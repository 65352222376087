import React, { useEffect, useContext } from "react";
// import { usePlaidLink, PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { usePlaidLink } from "react-plaid-link";
import Button from "plaid-threads/Button";

import Context from "../../Context";
import { stringify } from "querystring";

const cors = require('cors');

const Link = () => {
  const { linkToken, dispatch } = useContext(Context);

  const onSuccess = React.useCallback(
    (public_token: string, metadata: any) => {
      // send public_token to server
      const setToken = async () => {
        const response = await fetch("/api/set_access_token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: `public_token=${public_token}`,
        });
        if (!response.ok) {
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },

          });
          return;
        }
        ;
        const data = await response.json();
        dispatch({
          type: "SET_STATE",
          state: {
            itemId: data.item_id,
            accessToken: data.access_token,
            isItemAccess: true,
          },
        });

        const port = '9816'
        // const port = localStorage.getItem('dev') == 'true' ? "9820" : "9816";
        const baseurl = `http://localhost:${port}/api/CPCLOUD121/oauth2/linkcode`
        var url = baseurl + "?code=" + data.access_token + '&itemid=' + data.item_id + '&accountid=' + metadata.account.id;
        window.location.href = url;
        console.log(url);


      };
      setToken();
      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      window.history.pushState("", "", "/");
    },
    [dispatch]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  return (
    <Button type="button" large onClick={() => open()} disabled={!ready}>
      Connect Account With Plaid
    </Button>
  );
};
Link.displayName = "Link";

export default Link;
