import React, { useContext } from "react";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import InlineLink from "plaid-threads/InlineLink";

import Link from "../Link";
import Context from "../../Context";

import styles from "./index.module.scss";
import logo from '/home/cpadmin/plaid/frontend/src/images/CP_icon100.png'; // gives image path

const Header = () => {
  const {
    itemId,
    accessToken,
    linkToken,
    linkSuccess,
    isItemAccess,
    backend,
    linkTokenError,
  } = useContext(Context);

  return (
    <div className={styles.grid}>


      <h3 className={styles.title}>Clients &amp; Profits Cloud Connect</h3>

      {!linkSuccess ? (
        <>
          <h4 className={styles.subtitle}>
            Connect your account use Plaid
          </h4>
          <img src={logo} width='100' height='100' className={styles.logo} />
          <p className={styles.introPar}>
            Clients &amp; Profits allows you to seamlessesly connect your credit and bank accounts
            giving you the ability to import transaction directly into your company information.User will
            connect to their bank through the Plaid API.This allows users to access their accounts via the
            Plaid API.
          </p>
          {/* message if backend is not running and there is no link token */}
          {!backend ? (
            <Callout warning>
              Unable to fetch link_token: please make sure your backend server
              is running and that your.env file has been configured with your
              {/* <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>. */}
            </Callout>
          ) : /* message if backend is running and there is no link token */
            linkToken == null && backend ? (
              <Callout warning>
                <div>
                  Unable to fetch link_token: please make sure your backend server
                  is running and that your.env file has been configured
                  correctly.
                </div>
                <div>
                  Error Code: <code>{linkTokenError.error_code}</code>
                </div>
                <div>
                  Error Type: <code>{linkTokenError.error_type}</code>{" "}
                </div>
                <div>Error Message: {linkTokenError.error_message}</div>
              </Callout>
            ) : linkToken === "" ? (
              <div className={styles.linkButton}>
                <Button large disabled>
                  Loading...
                </Button>
              </div>
            ) : (
              <div className={styles.linkButton}>
                <Link />
              </div>
            )}
        </>
      ) : (
        <>
          {isItemAccess ? (
            <h4 className={styles.subtitle}>
              Congrats! Your account has been linked!
              {/*  You have{" "}
              <InlineLink
                href="http://plaid.com/docs/quickstart/glossary/#item"
                target="_blank"
              >
                Item
              </InlineLink> */}
              .
            </h4>
          ) : (
            <h4 className={styles.subtitle}>
              <Callout warning>
                Unable to link account. Please call the Clients &amp; Profits Help Desk 1-800-521-2166.
              </Callout>
            </h4>
          )}
          {/* comment this block below we don't need to show token and item id */}
          {/* <div className={styles.itemAccessContainer}>
            <p className={styles.itemAccessRow}>
              <span className={styles.idName}>item_id</span>
              <span className={styles.tokenText}>{itemId}</span>
            </p>

            <p className={styles.itemAccessRow}>
              <span className={styles.idName}>access_token</span>
              <span className={styles.tokenText}>{accessToken}</span>
            </p>
          </div>
          {isItemAccess && (
            <p className={styles.requests}>
              Now that you have an access_token, you can make all of the
              following requests:
            </p>
          )} */}
        </>
      )}
    </div>
  );
};

Header.displayName = "Header";

export default Header;
