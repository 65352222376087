import 'crypto';
import { createHash } from 'crypto';
export default function checkURLCode(code: string): boolean {
    console.log('code: ' + code)
    if (code.length === 0) {
        return false;
    }
    const counter = Math.floor((new Date().getTime() / 1000) / 60);
    const salt1 = counter.toString();
    const salt2 = (counter - 1).toString();
    const base = 'c<<AR_R2=C*de2SF8/&9~2xd';
    const pw1 = base + salt1;
    const pw2 = base + salt2;
    const hash1 = createHash('sha256').update(Buffer.from(pw1)).digest('hex');
    if (code !== hash1) {
        const hash2 = createHash('sha256').update(Buffer.from(pw2)).digest('hex');
        return code === hash2;
    }
    return true;
}